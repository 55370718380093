import React, {useState} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import AnimatedRoutes from './components/AnimatedRoutes';
import './styles/App.css';

const App = () => {

    const [fixedHeader, setFixedHeader] = useState(true); 
    var isLoggedIn = localStorage.getItem('email') ? true : '';
    const [images, setImages] = useState([]);
   

    return (
        <div className="min-h-screen bg-gradient-to-b from-[#ff5002] to-[#150824]">
            {fixedHeader && (
                <Header 
                    fixedHeader={fixedHeader}
                    isLoggedIn={isLoggedIn}
                />
            )}
            <div className="pt-20 flex flex-col items-center justify-center min-h-screen"> 
                <AnimatedRoutes 
                images={images}
                setImages={setImages}
                setFixedHeader={setFixedHeader}
                isLoggedIn={isLoggedIn}
                />
            </div>
        </div>
    );
};

// Create a wrapper component to use useNavigate
const AppWrapper = () => {
    return (
        <Router>
            <App />
        </Router>
    );
};

export default AppWrapper;
