import React, { useState } from 'react';
import STLogo from '../img/st-logo.png';
import '../styles/Datapack.css';
import '../styles/App.css';
import Checkmark from '../img/checkmark.png';

const STLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleLogin = async (event) => {
        event.preventDefault();

        setIsLoading(true); // Start the spinner when form is submitted

        const storedEmail = localStorage.getItem('email');
        const storedCompany = localStorage.getItem('company');

        const loginData = {
            email: email,
            password: password,
            storedEmail: storedEmail,
            storedCompany: storedCompany
        };

        try {
            const response = await fetch('https://uptickonboardingtools.com/st-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Login failed:', errorData.message);
                setErrorMessage(errorData.message);
                setIsLoading(false); // Stop loading spinner on error
                return;
            }

            const data = await response.json();
            setErrorMessage('');
            setIsLoading(false); // Stop spinner
            setShowSuccessMessage(true); // Show success message
            console.log("Login and data migration successful:", data.message);  // Log success

        } catch (error) {
            console.error('Error during login:', error);
            setErrorMessage('An unexpected error occurred. Please try again.');
            setIsLoading(false); // Stop loading spinner on error
        }
    };

    return (
        <div className="bg-[#150824] bg-opacity-40 p-10 rounded-lg w-[600px] mt-8">
            {/* Form */}
            {!isLoading && !showSuccessMessage && (
                <form className="flex flex-col h-auto" onSubmit={handleLogin}>
                    <h2 className="text-white font-montserrat text-2xl text-center">
                        Enter your 
                        <img 
                            src={STLogo} 
                            alt="Login Icon" 
                            className="inline mx-1" 
                            style={{ transform: 'scale(0.5)', verticalAlign: 'middle' }} 
                        /> 
                        login credentials
                    </h2>

                    <div className="mb-6">
                        <label className="block text-white font-montserrat mb-2">Username</label>
                        <input
                            type="text"
                            placeholder="Enter your service trade username"
                            className="w-full p-5 rounded-md bg-[#150824] border border-[#a8acb3] text-white focus:outline-none focus:ring-2 focus:ring-[#ff5002]"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className="mb-6">
                        <label className="block text-white font-montserrat mb-2">Password</label>
                        <input
                            type="password"
                            placeholder="Enter your service trade password"
                            className="w-full p-5 rounded-md bg-[#150824] border border-[#a8acb3] text-white focus:outline-none focus:ring-2 focus:ring-[#ff5002]"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    {errorMessage && (
                        <div className="text-red-500 text-center mb-4">
                            {errorMessage}
                        </div>
                    )}

                    <button
                        type="submit"
                        className="bg-[#ff5002] text-white font-montserrat font-bold px-4 py-2 rounded-md hover:bg-[#ff6f00] transition mb-4"
                    >
                        Sign In
                    </button>
                </form>
            )}

            {/* Loading Spinner */}
            {isLoading && !showSuccessMessage && (
                <div className="flex flex-col justify-center items-center mb-4">
                    <div className="relative inline-block w-16 h-16">
                        <div className="spinner-ring"></div>
                    </div>
                    <p className="text-white font-montserrat mt-4">
                        Loading Service Trade Data (this may take a few minutes) <span className="dots"></span>
                    </p>
                </div>
            )}

            {/* Success Message */}
            {showSuccessMessage && (
                <div className="text-center">
                    <img src={Checkmark} alt="Checkmark" className="h-16 mx-auto mb-4" />
                    <div className="text-green-500 text-xl font-montserrat">
                        Success! Your Uptick onboarding lead will be in contact soon with next steps.
                    </div>
                </div>
            )}
        </div>
    );
};

export default STLogin;
