import React, { useRef, useEffect, useState } from 'react';
import '../styles/BoxDrawer.css';

const FormMarkup = ({ images, setFixedHeader }) => {
    const canvasRef = useRef(null);
    const [boxes, setBoxes] = useState(images.map(() => [])); // Initialize boxes for each image
    const [isDrawing, setIsDrawing] = useState(false);
    const [startCoords, setStartCoords] = useState({ x: 0, y: 0 });
    const [editMode, setEditMode] = useState({});
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [image, setImage] = useState(null);
    const [multiplePages, setMultiplePages] = useState(images.length > 1); // Determine if there are multiple pages

    
    
    useEffect(() => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = images[currentImageIndex].url;

        img.onload = () => {
            setImage(img);
            const canvas = canvasRef.current;
            if (canvas) {
                canvas.width = img.width;
                canvas.height = img.height;
                const context = canvas.getContext('2d');
                context.drawImage(img, 0, 0);
                drawBoxes(context);
            }
        };

        img.onerror = (error) => {
            console.error('Error loading image:', error);
        };
    }, [currentImageIndex, images]);

    const drawBoxes = (context) => {
        boxes[currentImageIndex].forEach(box => drawBox(context, box));
    };

    const draw = (context) => {
        if (image) {
            context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            context.drawImage(image, 0, 0);
            drawBoxes(context);
        }
    };

    const handleMouseDown = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        setStartCoords({ x, y });
        setIsDrawing(true);
    };

    const handleMouseMove = (e) => {
        if (!isDrawing || !image) return;

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        draw(context);
        drawBox(context, {
            x: startCoords.x,
            y: startCoords.y,
            width: x - startCoords.x,
            height: y - startCoords.y
        });
    };

    const handleMouseUp = (e) => {
        if (!isDrawing) return;

        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const newBox = {
            x: startCoords.x,
            y: startCoords.y,
            width: x - startCoords.x,
            height: y - startCoords.y,
            type: 'text'
        };

        // Update the boxes for the current image
        const updatedBoxes = [...boxes];
        updatedBoxes[currentImageIndex].push(newBox);
        setBoxes(updatedBoxes);
        setEditMode(prev => ({ ...prev, [updatedBoxes[currentImageIndex].length - 1]: true }));
        setIsDrawing(false);
    };

    const drawBox = (ctx, box) => {
        ctx.fillStyle = 'rgba(255, 80, 2, 0.3)'; 
        ctx.fillRect(box.x, box.y, box.width, box.height);

        ctx.strokeStyle = '#ff5002';
        ctx.lineWidth = 2; 
        ctx.strokeRect(box.x, box.y, box.width, box.height);

        ctx.fillStyle = '#000'; 
        ctx.font = 'bold 12px Arial';
        ctx.textAlign = 'center';
        if (box.type) {
            ctx.fillText(box.type, box.x + box.width / 2, box.y + box.height / 2);}
    };

    const handleDropdownChange = (index, event) => {
        const updatedBoxes = [...boxes];
        updatedBoxes[currentImageIndex][index].type = event.target.value; 
        setBoxes(updatedBoxes);
        setEditMode(prev => ({ ...prev, [index]: false })); 
    };

    const handleBoxClick = (index) => {
        setEditMode(prev => ({ ...prev, [index]: true })); 
    };

    const handleOutsideClick = (event) => {
        const isBoxOrDropdownClicked = boxes[currentImageIndex].some((box, index) => {
            const boxElement = document.getElementById(`box-${index}`);
            return boxElement && (boxElement.contains(event.target));
        });

        if (!isBoxOrDropdownClicked) {
            setEditMode({}); 
        }
    };

    const handleDeleteBox = (index) => {
        const updatedBoxes = boxes.map((b, i) => 
            i === currentImageIndex ? b.filter((_, idx) => idx !== index) : b
        );
        setBoxes(updatedBoxes);
        setEditMode(prev => {
            const newEditMode = { ...prev };
            delete newEditMode[index]; 
            return newEditMode;
        });
    
        // Re-draw the canvas to reflect the changes
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        draw(context);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [boxes]);

    const captureScreenshot = (box) => {
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCanvas.width = box.width;
        tempCanvas.height = box.height;

        return new Promise((resolve) => {
            tempCtx.drawImage(
                image,
                box.x, box.y, box.width, box.height,
                0, 0, box.width, box.height
            );
            resolve(tempCanvas.toDataURL('image/png'));
        });
    };

    const handleSubmit = async () => {
        const screenshots = await Promise.all(boxes[currentImageIndex].map(async (box) => ({
            screenshot: await captureScreenshot(box),
            x: box.x,
            y: box.y,
            width: box.width,
            height: box.height,
            type: box.type
        })));

        console.log(screenshots);
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
    };

    const handleUndo = () => {
        const updatedBoxes = [...boxes];
        if (updatedBoxes[currentImageIndex].length > 0) {
            updatedBoxes[currentImageIndex].pop(); // Remove the last box
            setBoxes(updatedBoxes);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowLeft') {
            handlePrevImage();
        } else if (e.key === 'ArrowRight') {
            handleNextImage();
        } else if (e.ctrlKey && e.key === 'z') {
            e.preventDefault(); // Prevent the default action (like undoing in the browser)
            handleUndo(); // Call the undo function
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <canvas
                    ref={canvasRef}
                    style={{ position: 'absolute', top: 0, left: 0 }}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                />
                {image && (
                    <img
                        src={image.src}
                        alt="Drawing area"
                        style={{ display: 'block', maxWidth: '100%' }}
                    />
                )}
            </div>
            {multiplePages && (
                <>
                    <button 
                        onClick={handlePrevImage} 
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '-75px', // Adjusted to position outside the canvas
                            transform: 'translateY(-50%)',
                            background: 'white',
                            color: 'black',
                            font: 'bold 16px Arial', 
                            border: 'none',
                            borderRadius: '50%',
                            width: '50px', // Increased size
                            height: '50px', // Increased size
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        &lt;
                    </button>
                    <button 
                        onClick={handleNextImage} 
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '-75px', // Adjusted to position outside the canvas
                            transform: 'translateY(-50%)',
                            background: 'white',
                            color: 'black',
                            font: 'bold 16px Arial', 
                            border: 'none',
                            borderRadius: '50%',
                            width: '50px', // Increased size
                            height: '50px', // Increased size
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        &gt;
                    </button>
                </>
            )}
            {boxes[currentImageIndex].map((box, index) => (
                <div 
                    key={index} 
                    id={`box-${index}`} 
                    style={{ position: 'absolute', left: box.x + box.width + 10, top: box.y }}>
                    <div 
                        onClick={() => handleBoxClick(index)} 
                        style={{ cursor: 'pointer', position: 'relative' }}>
                        <button 
                            onClick={(e) => {
                                e.stopPropagation(); 
                                handleDeleteBox(index);
                            }} 
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                background: 'red',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}>
                            X
                        </button>
                    </div>
                    {editMode[index] && (
                        <select 
                            className="custom-dropdown" 
                            value={box.type} 
                            onChange={(e) => handleDropdownChange(index, e)}
                        >
                            <option value="text">Text</option>
                            <option value="multi-line text">Multi-line Text</option>
                            <option value="number">Number</option>
                            <option value="date">Date</option>
                            <option value="multiSelect">Multi-Select</option>
                            <option value="dropdown">Dropdown</option>
                            <option value="heading/display text">Heading/Display Text</option>
                            <option value="signature">Signature</option>
                            <option value="table">Table</option>
                        </select>
                    )}
                </div>
            ))}
        </div>
    );
};

export default FormMarkup;
