import React, { useState, useEffect } from 'react';
import UploadIcon from '../img/upload-icon.png';
import { useNavigate } from 'react-router-dom';

const TemplateUpload = ({ setImages }) => {
    const [file, setFile] = useState(null);
    const [dragActive, setDragActive] = useState(false);
    const [placeholder, setPlaceholder] = useState('Drag and drop your file here or click to browse');
    const [isImagesSet, setIsImagesSet] = useState(false);

    const navigate = useNavigate();

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type === 'application/pdf') {
            setFile(selectedFile);
            setPlaceholder(selectedFile.name);
        } else {
            alert('Please upload a valid PDF file.');
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragActive(true);
        setPlaceholder("");
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragActive(false);
        setPlaceholder('Drag and drop your file here or click to browse');
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const droppedFile = e.dataTransfer.files[0];
            setFile(droppedFile);
            setPlaceholder(droppedFile.name);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
    
            try {
                const response = await fetch('https://uptickonboardingtools.com/templateupload', {
                    method: 'POST',
                    body: formData,
                });
    
                if (response.ok) {
                    const data = await response.json();
                    setImages(data.image_urls);
                    setIsImagesSet(true); 
                } else {
                    console.error('File upload failed');
                    const errorData = await response.json();
                    console.error('Error response from backend:', errorData);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            console.log('No file selected');
        }
    };

    useEffect(() => {
        if (isImagesSet) {
            navigate('/form-prep/markup'); // Navigate only after images are set
            setIsImagesSet(false); // Reset after navigating
        }
    }, [isImagesSet, navigate]);

    return (
        <div className="bg-[#150824] bg-opacity-40 p-10 rounded-lg w-[700px] h-[450px] mt-3 grid grid-rows-7 gap3">
            <h3 className="text-white font-montserrat text-3xl text-center mb-6 row-start-1 row-end-2">
                Upload empty form to begin<span className="dots"></span>
            </h3>
            <h3 className="text-white font-montserrat text-1xl text-center mb-6 row-start-2 row-end-3">
                <em>Accepted file types</em>: .PNG, .JPG, & .PDF
            </h3>
            
            <form className="flex flex-col h-auto row-start-4 row-end-6" onSubmit={handleSubmit}>
                <div
                    className={`mb-6 p-10 border-2 border-dashed rounded ${dragActive ? 'border-[#ff5002]' : 'border-gray-300'} flex items-center justify-center`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <label className="block text-white font-montserrat mb-2 text-center" htmlFor="fileInput">
                        {dragActive ? (
                            <img src={UploadIcon} alt="Upload Icon" className="animate-upload-icon" />
                        ) : (
                            placeholder
                        )}
                    </label>
                    <input
                        type="file"
                        id="fileInput"
                        className="hidden"
                        onChange={handleFileChange}
                    />
                </div>
                <button
                    type="submit"
                    className="bg-gradient-to-r from-[#ff5002] to-[#ff6f00] rounded-lg shadow-lg p-4 flex items-center justify-center hover:shadow-xl transition-transform transform hover:scale-105 text-white text-lg h-10 w-50"
                >
                    Upload
                </button>
            </form>
        </div>
    );
};

export default TemplateUpload;
